let settings = {
  isLocal: process.env.NODE_ENV === 'local',
  isDev: process.env.NODE_ENV === 'development',
  isStaging: process.env.NODE_ENV === 'staging',
  isProduction: process.env.NODE_ENV === 'production',
  ASSET_PATH: "/assets/",
  GACode: "G-7YR14SD3YZ",
  GTagCode: "AW-433018138",
  GTMId: 'GTM-TF4BQK6',
  isServer: () => {
     return ! (typeof window != 'undefined' && window.document);
  },
  // googleReCaptchaKey: 'GOOGLE_RECAPTCHA_KEY',
  host: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : 'https://www.kudosgolf.com/',
  // host: 'https://www.kudosgolf.com/',
  shade: function(color, percent) {
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
  }
}

export default settings;
