import settings from "./settings";
import {
  install as installGTag,
  gtag
} from "ga-gtag";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

export default {
  setup: () => {
    if (!settings.isServer() && !window.INITIALIZED_GA) {
      TagManager.initialize({
        gtmId: settings.GTMId,
      });

      installGTag(settings.GTagCode, {
        send_page_view: false,
      });

      // using gtag here because we already used installGTag before
      // This gTag was provided by Jimmy for Oakville location tracking
      gtag('config', 'G-VRN5CMPT46');

      ReactGA.initialize(settings.GACode);

      window.INITIALIZED_GA = true;
      return ReactGA;
    } else if (window.INITIALIZED_GA) {
      return ReactGA;
    }
  },
};